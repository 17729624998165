<template>
  <div>
    <h3 class="my-4 primary-text--text">
      {{ $t("labels.data_cottons") }}
    </h3>

    <div class="my-5">
      <p class="my-3 secondary-text--text">
        {{ $t("labels.benefit") }}
      </p>

      <p class="my-3 primary-text--text">
        {{ productiveUnit.beneficiamento }}
      </p>

      <v-divider />

      <div v-if="productiveUnit.algodoeiras">
        <div
          v-for="(algodoeira, index) in productiveUnit.algodoeiras"
          :key="index"
        >
          <v-row class="py-4">
            <v-col sm="1">
              <p class="ma-0 key-row primary-text--text">
                {{ index + 1 }}
              </p>
            </v-col>

            <v-col sm="2">
              <div class="d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.id") }}
                </p>
                <p class="ma-0 primary-text--text">
                  {{ algodoeira.id || defaultEmptyData }}
                </p>
              </div>
            </v-col>

            <v-col sm="2">
              <div class="d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.fantasy_name") }}
                </p>
                <p class="ma-0 primary-text--text">
                  {{ algodoeira.nome_fantasia || defaultEmptyData }}
                </p>
              </div>
            </v-col>

            <v-col sm="2">
              <div class="d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.code_gs1") }}
                </p>
                <p class="ma-0 primary-text--text">
                  {{ algodoeira.codigo_gs1 || defaultEmptyData }}
                </p>
              </div>
            </v-col>

            <v-col sm="2">
              <div class="d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.digit_extension") }}
                </p>
                <p class="ma-0 primary-text--text">
                  {{ algodoeira.digito_extensao || defaultEmptyData }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CottonData",

  props: {
    productiveUnit: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    defaultEmptyData: "Nao informado"
  })
};
</script>

<style lang="scss" scoped>
.key-row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
