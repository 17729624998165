<template>
  <div>
    <h3 class="my-4 primary-text--text">
      {{ $t("labels.registered_enrollments") }}
    </h3>

    <div class="py-4" v-for="(composition, index) in compositions" :key="index">
      <v-row>
        <v-col sm="1">
          <p class="ma-0 key-row primary-text--text">
            {{ index + 1 }}
          </p>
        </v-col>

        <v-col sm="2">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $tc("labels.registration", 1) }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.matricula.numero_matricula || defaultEmptyData }}
            </p>
          </div>
        </v-col>

        <v-col sm="2">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.type") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.tipo || defaultEmptyData }}
            </p>
          </div>
        </v-col>

        <v-col sm="1">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.uf") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.matricula.cartorio.uf || defaultEmptyData }}
            </p>
          </div>
        </v-col>

        <v-col sm="2">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.city") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.matricula.cartorio.municipio || defaultEmptyData }}
            </p>
          </div>
        </v-col>

        <v-col sm="2">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.registry") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.matricula.cartorio.nome || defaultEmptyData }}
            </p>
          </div>
        </v-col>

        <v-col sm="2">
          <div class="d-flex flex-column">
            <p class="ma-0 secondary-text--text">
              {{ $t("labels.total_area") }}
            </p>
            <p class="ma-0 primary-text--text">
              {{ composition.area_total || defaultEmptyData }}
            </p>
          </div>
        </v-col>
      </v-row>
      <div
        class="pb-4"
        v-for="(matricula_produtor,
        secondIndex) in composition.matricula_produtores"
        :key="secondIndex"
      >
        <v-row class="ml-10">
          <v-col sm="1">
            <p class="ma-0 key-row primary-text--text">
              {{ formatKeyRow(index, secondIndex) }}
            </p>
          </v-col>

          <v-col sm="2">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.type") }}
              </p>
              <p class="ma-0 primary-text--text">
                {{
                  matricula_produtor.produtor_ou_grupo.tipo || defaultEmptyData
                }}
              </p>
            </div>
          </v-col>

          <v-col sm="2">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.producer_or_group") }}
              </p>
              <p class="ma-0 primary-text--text">
                {{
                  matricula_produtor.produtor_ou_grupo.nome || defaultEmptyData
                }}
              </p>
            </div>
          </v-col>

          <v-col sm="2">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.cpf_cnpj") }}
              </p>
              <p class="ma-0 primary-text--text">
                {{
                  matricula_produtor.produtor_ou_grupo.cpf_cnpj_formatado ||
                    defaultEmptyData
                }}
              </p>
            </div>
          </v-col>
          <v-col sm="2">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.state_registration") }}
              </p>
              <p class="ma-0 primary-text--text">
                {{ matricula_produtor.inscricao_estadual || defaultEmptyData }}
              </p>
            </div>
          </v-col>
          <v-col sm="2">
            <div class="d-flex flex-column">
              <p class="ma-0 secondary-text--text">
                {{ $t("labels.area_ha") }}
              </p>
              <p class="ma-0 primary-text--text">
                {{ matricula_produtor.area_total || defaultEmptyData }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider />
    </div>

    <div class="my-5"></div>
  </div>
</template>

<script>
export default {
  name: "RegisteredEnrollments",
  props: {
    compositions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    defaultEmptyData: "Não informado",
    items: [
      {
        matricula: "111",
        tipo: "S",
        uf: "DF",
        municipio: "Brasília",
        cartorio: "1º Ofício do Registro de Imóveis, Títulos e Documentos",
        area: "3.800",
        produtores: [
          {
            tipo: "Produtor",
            produtor_grupo: "Onofre Pedro Botan",
            cpf_cnpj: "750.831.810-29",
            inscricao_estadual: "132229013",
            area: "3.000"
          },
          {
            tipo: "Produtor",
            produtor_grupo: "José Antônio Botan",
            cpf_cnpj: "750.831.810-29",
            inscricao_estadual: "132229013",
            area: "800"
          }
        ]
      },
      {
        matricula: "111",
        tipo: "S",
        uf: "DF",
        municipio: "Brasília",
        cartorio: "1º Ofício do Registro de Imóveis, Títulos e Documentos",
        area: "3.800",
        produtores: [
          {
            tipo: "Produtor",
            produtor_grupo: "Onofre Pedro Botan",
            cpf_cnpj: "750.831.810-29",
            inscricao_estadual: "132229013",
            area: "3.000"
          },
          {
            tipo: "Produtor",
            produtor_grupo: "José Antônio Botan",
            cpf_cnpj: "750.831.810-29",
            inscricao_estadual: "132229013",
            area: "800"
          }
        ]
      }
    ]
  }),
  methods: {
    formatKeyRow(firstIndex, secondIndex) {
      return `${firstIndex + 1}.${secondIndex + 1}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.key-row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
